import IFTTTNextStory from "app/components/ifttt_next/stories/index"
import InitializeFacebook from "app/scripts/initialize_facebook"
import SetupUnifiedHeaderDropdown from "shared/scripts/setup_unified_header_dropdown"
import SetupUnifiedMobileMenu from "shared/scripts/setup_unified_mobile_menu"
import ShareButton from "shared/components/share_button"

Object.assign(window.App.Comps, {
  IFTTTNextStory,
  ShareButton,
})

Object.assign(window.App.Scripts, {
  InitializeFacebook,
  SetupUnifiedHeaderDropdown,
  SetupUnifiedMobileMenu,
})
